import {Menu, IconButton, MenuItem, Box} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { withFirebase } from '../Firebase';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';



const AlertMenu = (props: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation("translation");

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <Box>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ mb: 1 }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={async () => { setAnchorEl(null); await props.MarkAllAlertsAsRead(); }}>
                    {t("components.alert.readAll")}
                </MenuItem>

            </Menu>
        </Box>
    );
}

export default withFirebase(AlertMenu);
