import useTranslation from 'next-translate/useTranslation';
import { Typography } from "@mui/material";

export const MessageText = (props: any) => {
    const { t } = useTranslation("translation");
    let message: string;

    switch (props.activityType) {
        case 0:
            message = t("components.alert.friendRequest");
            break;
        case 1:
            message = t("components.alert.accepted");
            break;
        case 2:
            message = t("components.alert.participate");
            break;
        case 3:
            message = t("components.alert.newComment");
            break;
        case 4:
            message = t("components.alert.friendAddedComment");
            break;
        case 5:
            message = t("components.alert.likes");
            break;
        default:
            message = "";
            break;
    }
    return (
        <>
            <Typography variant='body1'>{message}</Typography>
        </>
    );
};
