import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import * as roles from "@/constants/roles";
import * as ROUTES from "@/constants/routes";
import useTranslation from "next-translate/useTranslation";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { ReactComponent as ArrowRight } from "@/assets/arrowRight.svg";
import { ReactComponent as Calendar } from "@/assets/runningCalendar.svg";
import { ReactComponent as LockLocked } from "@/assets/lockLocked.svg";
import { ReactComponent as Heart } from "@/assets/heartBroken.svg";
import { ReactComponent as Camera } from "@/assets/cameraBroken.svg";
import { ReactComponent as Ticket } from "@/assets/ticket.svg";
import { ReactComponent as Friends } from "@/assets/friends.svg";
import { ReactComponent as Add } from "@/assets/addcircle.svg";
import { ReactComponent as Guide } from "@/assets/guide.svg";
import { ReactComponent as Logout } from "@/assets/logout.svg";
import { ReactComponent as CloseIcon } from "@/assets/exit.svg";
import { ReactComponent as Settings } from "@/assets/settings.svg";
import { ReactComponent as Dashboard } from "@/assets/dashboard.svg";
import { ReactComponent as Events } from "@/assets/events.svg";
import { ReactComponent as Profile } from "@/assets/person.svg";
import Image from "next/image";

import Link from "next/link";
import LinkMenu from "./LinkMenu";
import { withFirebase } from "../Firebase";
import { mutate } from "swr";
import { useRouter } from "next/router";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
}));

const linkStyling = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    py: 2,
    pl: "20px",
    fontWeight: 500,
};

const iconStyling = {
    color: "#1a1a1a",
    fontWeight: "600",
    cursor: "pointer",
    mr: 1,
};
const menuItems = {
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    cursor: "pointer",
    "&:hover *": {
        color: "#FF8640",
    },
};

const ProfileMenu = (props: any) => {
    const { t } = useTranslation("translation");
    const drawerWidth = props.mobile ? "100%" : 240;
    const user = props.authUser;
    const router = useRouter();

    const handleSignOut = async () => {
        await props.firebase.doSignOut();
        mutate("userData", null, false);
        router.push(ROUTES.HOME);
        props.handleDrawerClose();
    };

    const profile = [
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("profilePage:tabs.personalPlan"),
            icon: <LockLocked width={17} />,
            state: { programs: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("profilePage:tabs.discount"),
            icon: <LockLocked width={17} />,
            state: { deals: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("profilePage:tabs.masterClassView"),
            icon: <LockLocked width={17} />,
            state: { course: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.savedRuns"),
            icon: <Heart width={15} />,
            state: { favourite: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.participateRuns"),
            icon: <Calendar width={17} />,
            state: { participate: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.images"),
            icon: <Camera width={17} />,
            state: { images: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.friendList"),
            icon: <Friends width={18} />,
            state: { friends: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.ticketView"),
            icon: <Ticket width={16} />,
            state: { tickets: true },
        },
        {
            path: ROUTES.PROFILE,
            onClick: () => props.handleDrawerClose(),
            text: t("profilePage:ctaTextSettings"),
            icon: <Settings width={16} />,
            state: { settings: true },
        },
    ];

    const organizer_links = [
        {
            path: { pathname: ROUTES.EDIT_RUN, query: { newevent: true } },
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.addRace"),
            icon: <Add width={15} />,
        },
        {
            path: { pathname: ROUTES.ORGANIZER_PORTAL, query: { path: "dashboard" } },
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.dashboard"),
            icon: <Dashboard width={15} />,
        },
        {
            path: { pathname: ROUTES.ORGANIZER_PORTAL, query: { path: "events" } },
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.events"),
            icon: <Events width={15} />,
        },
        {
            path: { pathname: ROUTES.ORGANIZER_PORTAL, query: { path: "profile" } },
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.settings"),
            icon: <Profile width={15} />,
        },
        {
            path: "https://firebasestorage.googleapis.com/v0/b/nestelop-production.appspot.com/o/Documents%2FRunAgain%20-%20Veiledning%20til%20arrang%C3%B8rportalen.pdf?alt=media&token=57ea7a63-fb48-42e2-b599-476cc61dbee5",
            onClick: () => props.handleDrawerClose(),
            text: t("components.navigation.guideButton"),
            icon: <Guide width={17} />,
            target: true,
        },
    ];

    return (
        <Drawer
            sx={{
                width: { xs: drawerWidth, lg: "350px" },
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    height: { xs: "100%", lg: "80vh" },
                    top: { xs: 0, lg: 105 },
                    right: { xs: 0, lg: 10 },
                    width: { xs: drawerWidth, lg: "350px" },
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                    borderRadius: { xs: "0px", lg: "10px" },
                    backgroundColor: "#fcfcfc",
                },
            }}
            variant="persistent"
            anchor="right"
            disableScrollLock
            open={props.open}
        >
            <Box
                sx={{
                    overflowY: { xs: "scroll", md: "auto" },
                    height: "100vh",
                    backgroundColor: "#fcfcfc",
                }}
            >
                <DrawerHeader>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "center",
                            pt: { xs: 0, lg: 2 },
                        }}
                    >
                        <IconButton
                            sx={{ marginLeft: "auto", mt: 1, display: { xs: "block", lg: "none" } }}
                            onClick={props.handleDrawerClose}
                        >
                            <CloseIcon width={25} />
                        </IconButton>
                        <Box sx={{ display: "flex" }}>
                            {user?.photoURL ? (
                                <Image
                                    src={user?.photoURL}
                                    width="80"
                                    height="80"
                                    quality={50}
                                    alt="ProfileImage"
                                    style={{ borderRadius: "80px" }}
                                />
                            ) : (
                                <Profile width={80} fill="#808080" />
                            )}
                        </Box>
                        <Box sx={{ display: "flex" }} mb={3}>
                            <Typography
                                variant="h6"
                                pt={"10px"}
                                pb={"2px"}
                                sx={{ fontWeight: "600" }}
                            >
                                {user?.firstName} {user?.lastName}
                            </Typography>
                        </Box>
                    </Box>
                </DrawerHeader>
                <Divider />
                <Box sx={linkStyling}>
                    {user && (
                        <Link href={ROUTES.PROFILE}>
                            <Typography
                                variant="h6"
                                pt={1}
                                pb={1}
                                onClick={props.handleDrawerClose}
                                sx={iconStyling}
                            >
                                {t("components.navigation.profile")}
                                <Box sx={{ position: "relative", float: "right" }}>
                                    <ArrowRight width={15} stroke="black" />
                                </Box>
                            </Typography>
                        </Link>
                    )}

                    {user?.role?.includes(roles.RACE_ADMIN) && (
                        <Link href={ROUTES.RACE_ADMIN_PORTAL}>
                            <Typography
                                variant="body1"
                                pt={1}
                                pb={1}
                                onClick={props.handleDrawerClose}
                                sx={iconStyling}
                            >
                                <PersonOutlineOutlinedIcon sx={{ mr: 1 }} />
                                {t("components.navigation.raceAdminPortal")}
                            </Typography>
                        </Link>
                    )}
                    {profile.map((linkItem: any, index: number) => (
                        <LinkMenu key={index} {...linkItem} />
                    ))}
                </Box>
                <Divider />
                {user?.role?.includes(roles.ORGANIZER) && (
                    <Box sx={linkStyling}>
                        <Link href={ROUTES.ORGANIZER_PORTAL}>
                            <Typography
                                variant="h6"
                                pt={1}
                                pb={1}
                                onClick={props.handleDrawerClose}
                                sx={iconStyling}
                            >
                                {t("components.navigation.organizerPortal")}
                                <Box sx={{ position: "relative", float: "right" }}>
                                    <ArrowRight width={15} stroke="black" />
                                </Box>
                            </Typography>
                        </Link>
                        {organizer_links.map((linkItem: any, index: number) => (
                            <LinkMenu key={index} {...linkItem} />
                        ))}
                    </Box>
                )}
                <Divider />
                <Box sx={linkStyling}>
                    {user && (
                        <Box
                            sx={{ ...menuItems, pt: 0 }}
                            onClick={async () => await handleSignOut()}
                        >
                            <Logout width={17} />
                            <Typography sx={iconStyling} variant="body1" py={1} ml={1}>
                                {t("components.navigation.logOut")}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
};

export default withFirebase(ProfileMenu);
