import { useState, useEffect } from "react";

import { IAlertComponentProps } from "./Interfaces/IAlertComponentProps";
import { IAlertMessageProps } from "./Interfaces/IAlertMessageProps";
import * as routes from "@/constants/routes";

import AlertMessage from "./AlertMessage";
import { Badge, Box, Button, CircularProgress, List, Tab, Tabs, Typography } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { getListOfUsers } from "@/HttpCalls/GetAllUsers";
import TabPanel from "../TabPanel/TabPanel";
import FriendCard from "@/content/find-friends/components/FriendCard";
import FriendCardProps from "@/content/find-friends/interfaces/friendCardInterface";
import { sleep } from "@/helpers/sleep";
import { withFirebase } from "../Firebase";
import { FindRunsRequest } from "@/HttpCalls/runs/FindRunRequest";
import { unPackSearchResult } from "@/helpers/getRuns";
import AlertMenu from "./AlertMenu";
import { get_activities } from "@/HttpCalls/runs/userEngagement";

const sliceLength = 8;

const AlertComponent = (props: IAlertComponentProps) => {
    const [alerts, setAlerts] = useState<any[]>([]);
    const [numberOfFriendRequests, setNumberOfFriendRequests] = useState(0);
    const [friendRequests, setFriendRequests] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [maxAlerts, setMaxAlerts] = useState(0);
    const [value, setValue] = useState(0);
    const [step, setStep] = useState(0);
    const { t } = useTranslation("translation");

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            const friendRequests = props.user?.friendRequests?.split(";");
            if (Array.isArray(friendRequests)) {
                let tempFriendRequests: any[] = [];
                for (let i = 0; i < 5; i++) {
                    try {
                        const response = await getListOfUsers(friendRequests);
                        if (response.status === 200) {
                            tempFriendRequests = response.data;
                            break;
                        } else {
                            await sleep(1000);
                        }
                    } catch {
                        await sleep(1000);
                    }
                }
                setFriendRequests(tempFriendRequests);
                setNumberOfFriendRequests(tempFriendRequests.length);
            }
        };
        fetchUsers();
    }, [props.user]);

    const fetchActivities = async (activityIds: string[], step:number, sliceNumber: number) => {
        setLoading(true);
        setMaxAlerts(activityIds.length);
        let fetchActivities = [...activityIds];
        fetchActivities = fetchActivities.reverse().slice(step*sliceNumber, (step*sliceNumber) + sliceNumber);

        const token = await props.firebase?.getIdtokenWithretry();
        const response = await get_activities(fetchActivities, token);
        let activities = [];
        if (response.status === 200) {
            activities = response.data;
        }

        const tempActivities: any = [];
        const runGuids = [];
        for (let i = 0; i < activities.length; i++) {
            const data = activities[i];
            const newActivity: any = {
                id: activities[i]._firestore_id,
                activityType: data.activityType,
                username: data.data.username,
            };
            for (let i = 0; i < 5; i++) {
                try {
                    const response = await getListOfUsers([data.data.username]);
                    if (response.status === 200) {
                        const responseData = response.data[0];
                        newActivity["name"] = responseData.firstName + " " + responseData.lastName;
                        newActivity["photoURL"] = responseData.photoURL;

                        break;
                    } else {
                        await sleep(1000);
                    }
                } catch {
                    await sleep(1000);
                }
            }
            if ([2, 3, 4, 5].includes(data.activityType)) {
                runGuids.push(data.data.run_guid);

                newActivity["run_guid"] = data.data.run_guid;
            }
            tempActivities.push(newActivity);
        }
        const runs: any[] = [];
        const chunkSize = 20;

        for (let i = 0; i < runGuids.length; i += chunkSize) {
            const result = await FindRunsRequest(
                "",
                "race_guid IN [" +
                    runGuids.splice(i, i + chunkSize).map((item: string) => "'" + item + "'") +
                    "]"
            );
            const run = await unPackSearchResult({
                runs: result.data["hits"],
                date: null,
                toDate: null,
            });
            runs.push(...run);
        }

        for (let i = 0; i < tempActivities.length; i++) {
            const tempRun = runs.find((e: any) => e.race_guid === tempActivities[i]["run_guid"]);

            tempActivities[i]["date"] = tempRun?.date;
            tempActivities[i]["title"] = tempRun?.post_title;
            tempActivities[i]["post_url"] = tempRun?.post_url;
        }

        setAlerts((oldActivities:any)=> [...oldActivities, ...tempActivities]);
        setLoading(false);
    };

    const loadMore = () => {
        const activityIds = props.user?.alerts.activities;
        fetchActivities(activityIds, step, sliceLength);
        setStep(step + 1);
    };
    const MarkAllAlertsAsRead = async () => {
        props.readAllAlerts();
        fetchActivities([], step, sliceLength);
        await props.firebase.readAllAlerts();
        const activityIds = props.user?.alerts.activities;
        fetchActivities(activityIds, step,sliceLength);
        
    };

    useEffect(() => {
        if (
            Object.prototype.hasOwnProperty.call(props.user, "alerts") &&
            "activities" in props.user.alerts
        ) {
            const activityIds = props.user?.alerts.activities;
            fetchActivities(activityIds, step, sliceLength);
            setStep(step + 1);
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user.alerts]);

    return (
        <Box pt={2} sx={{ width: { xs: "100%", lg: "350px" } }}>
            <Box sx={{ display: "flex", ml: { xs: "0px", md: "20px" } }}>
                <Typography variant="h6" pt={1}>
                    {t("components.alert.alerts")}
                </Typography>
                <AlertMenu MarkAllAlertsAsRead={MarkAllAlertsAsRead} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Tabs
                    value={value}
                    sx={{ width: "100%" }}
                    indicatorColor="primary"
                    centered
                    onChange={handleChange}
                    aria-label="Notification tabs"
                >
                    <Tab label={t("components.alert.alerts")} sx={{ width: "40%" }} />
                    <Tab label={t("components.alert.friendRequest")} sx={{ width: "40%", p: 1 }} />
                </Tabs>
                <Badge
                    badgeContent={<>{numberOfFriendRequests}</>}
                    color="primary"
                    sx={{ top: "15px", right: "35px" }}
                />
            </Box>
            <TabPanel value={value} index={0}>
                <List sx={{ height: "70vh", minWidth: "300px", pt: 3, overflowY: 'scroll' }}>
                    {alerts?.map((alert: IAlertMessageProps) => {
                        return (
                            <AlertMessage
                                key={alert.id}
                                {...alert}
                                user={props.user}
                                close={() => {
                                    if (
                                        props.firebase?.currentUser?.alerts?.un_read.includes(
                                            alert.id
                                        )
                                    ) {
                                        props.readAlert();
                                    }
                                    props.close();
                                }}
                            />
                        );
                    })}

                    {loading && (
                        <Box sx={{ display: "flex", width: "200px", margin: "auto" }}>
                            <Typography component="legend">
                                {t("components.alert.loading")}
                            </Typography>
                            <CircularProgress color="primary" />
                        </Box>
                    )}
                    {step < maxAlerts && (
                        <Box sx={{ display: "flex", width: "200px", margin: "auto" }}>
                            <Button onClick={loadMore}>{t("blogPage.showMore")}...</Button>
                        </Box>
                    )}
                </List>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <List sx={{ minWidth: "300px", pt: 3 }}>
                    {friendRequests.map((user: FriendCardProps, index: number) => {
                        return (
                            <Box key={index} sx={{ margin: "24px" }} onClick={() => props.close()}>
                                <FriendCard
                                    {...user}
                                    alertView={true}
                                    markAsread={() =>
                                        setNumberOfFriendRequests(numberOfFriendRequests - 1)
                                    }
                                />
                            </Box>
                        );
                    })}
                    {
                        <Box p={1}>
                            <Typography component="h3" sx={{ maxWidth: "300px" }}>
                                {t("components.alert.noFriends.part1")}{" "}
                                <a href={routes.FIND_FRIENDS}>
                                    {t("components.alert.noFriends.part2")}
                                </a>{" "}
                                {t("components.alert.noFriends.part3")}
                            </Typography>
                        </Box>
                    }
                </List>
            </TabPanel>
        </Box>
    );
};

export default withFirebase(AlertComponent);
