import { Avatar, Typography, ListItem, List, ListItemIcon, ListItemText } from "@mui/material";
import { withFirebase } from "../Firebase";
import { MessageText } from "./MessageText";
import { useRouter } from "next/router";
import * as routes from "@/constants/routes";
import { useEffect, useState } from "react";

const classes = {
    listItem: {
        padding: 2,
        borderRadius:3,
        mb: -1.5,
        mx: 1,
        width: 'auto',
        "&:hover": {
            backgroundColor: "#efefef"
        },
    },
};

const AlertMessage = (props: any) => {
    const [unRead, setUnRead] = useState(props.user.alerts.un_read.includes(props.id));
    const router = useRouter();

    const handleAlertClick = async () => {
        props.close();
        setUnRead(false);
        props.firebase.readAlert(props.id);

        let url = "";
        let query: any = {
            fromAlert: true,
            activityId: props.id,
        };
        switch (props.activityType) {
            case 0:
                url = routes.PROFILE + "/" + props.username;
                break;
            case 1:
                url = routes.PROFILE + "/" + props.username;
                break;
            case 2:
                url = routes.PROFILE + "/" + props.username;
                break;
            case 3:
                url = "/run/" + props.post_url;
                query = { comments: true };
                break;
            case 4:
                url = "/run/" + props.post_url;
                query = { comments: true };
                break;
            case 5:
                url = "/run/" + props.post_url;
                query = { comments: true };
                break;
            default:
                url = "";
                break;
        }

        router.push({
            pathname: url,
            query: query,
        });
    };


    return (
        <List>
            <ListItem
                onClick={handleAlertClick}
                sx={{ ...classes.listItem, ...{ backgroundColor: unRead ? "#f5f5f5" : "transparent" }}}
            >
                <ListItemIcon>
                    <Avatar src={props.photoURL} />
                </ListItemIcon>
                <ListItemText 
                    primary={
                        <>
                            <Typography
                                sx={{ fontSize: "14px", fontWeight: "bolder" }}
                                variant="body1"
                            >
                                {props.name}
                            </Typography>
                            <MessageText activityType={props.activityType} />
                            {props.activityType === 2 && (
                                <Typography
                                    sx={{ fontSize: "14px", fontWeight: "bolder" }}
                                    variant="body1"
                                >
                                    {props.title}
                                </Typography>
                            )}
                        </>
                    }
                />
            </ListItem>
        </List>
    );
};

export default withFirebase(AlertMessage);
